import React, { useState } from 'react';
import styled from 'styled-components';
import { Hero, SEO, StaticCaseStudies, LikeWhatYouSee } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import PrevArrow from 'images/shared/PrevArrow';

const StudioPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        thumbnailImage: file(relativePath: { eq: "service-pages/design-video-thumbnail.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
          }
        }
        banner2Image: file(relativePath: { eq: "service-pages/creative-studio-banner-2.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
          }
        }
        banner3Image: file(relativePath: { eq: "service-pages/creative-studio-banner-3.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
          }
        }
        alnylamImage: file(relativePath: { eq: "service-pages/brand-and-design/alnylam.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
          }
        }
        iphaConference2024: file(
          relativePath: { eq: "service-pages/brand-and-design/ipha-conference-2024.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
          }
        }
      }
    `
  );

  const caseStudies = [
    {
      slug: '/our-work/alnylam-pharmaceuticals-disease-awareness-campaign/',
      image: data.alnylamImage,
      title: 'Disease Awareness Campaign, HCP Engagement Campaign',
      company: 'Alnylam Pharmaceuticals'
    },
    {
      slug: '/our-work/ipha-conference-2024/',
      image: data.iphaConference2024,
      title: 'Event Creation, Design and Production',
      company: 'IPHA Conference 2024'
    }
  ];

  return (
    <>
      <SEO
        pageSpecificTitle="Design & Development"
        pageSpecificDescription="In House Design and Development Studio, Producing Full Funnel Creative Across All Media"
      />
      <Hero>
        <HeroContent>
          <Directions>
            <StyledLink to="/#services">
              <BackButton>
                <StyledPrevArrow />
              </BackButton>
            </StyledLink>
            <StyledLink to="/services/healthcare-events-and-conferences">
              <NextButton>
                <StyledNextArrow />
              </NextButton>
            </StyledLink>
          </Directions>
          <h1>
            Design & <br />
            Development
          </h1>
          <Text>
            <h2>
              In House Design and Development Studio, Producing Full Funnel Creative Across All
              Media
            </h2>
            <p>
              Everything we ideate, design, code, and produce is original and custom-made with
              pride.
            </p>
          </Text>
        </HeroContent>
      </Hero>
      <Section style={{ padding: 0 }}>
        <Video>
          <GatsbyImage image={getImage(data.thumbnailImage)} alt="Video thumbnail" />
          <video
            muted
            autoPlay
            loop
            playsInline
            id="Design & Development"
            preload="auto"
            controls={false}>
            <source src="/design-video.mp4" type="video/mp4" />
          </video>
        </Video>
      </Section>
      <Section>
        <Article>
          <ArticleTitle>Our Approach</ArticleTitle>
          <Column>
            <p>
              Our creative offering spans brand design to conceptual campaigns to website and app
              building, and video and livestream production.
            </p>
          </Column>
          <Column>
            <p>
              We have an in - house design and development team and our own production studios, with
              a team of highly creative experts bringing crafting excellence to each and every
              project.
            </p>
          </Column>
        </Article>
      </Section>
      <Section style={{ padding: '4rem 0 0' }}>
        <GatsbyImage image={getImage(data.banner2Image)} alt="Banner" />
      </Section>
      <Section>
        <Article>
          <ArticleTitle>Our Design Services at a Glance</ArticleTitle>
          <Column>
            <ul>
              <li>Creative Campaigns</li>
              <li>Digital Advertising Campaigns</li>
              <li>Social Media Campaigns</li>
              <li>Graphic Design</li>
              <li>Branding</li>
            </ul>
          </Column>
          <Column>
            <ul>
              <li>Print POS & Packaging</li>
              <li>OOH (Out of Home) Campaigns</li>
              <li>Video Storyboarding</li>
              <li>Web/App Design</li>
              <li>3D Artwork</li>
            </ul>
          </Column>
        </Article>
      </Section>
      <Section style={{ padding: '4rem 0 0' }}>
        <GatsbyImage image={getImage(data.banner3Image)} alt="Banner" />
      </Section>
      <Section>
        <Article>
          <ArticleTitle>Our Development Services at a Glance</ArticleTitle>
          <Column>
            <ul>
              <li>UI/UX </li>
              <li>Web Strategy & Wireframing</li>
              <li>Brochure Websites</li>
            </ul>
          </Column>
          <Column>
            <ul>
              <li>Virtual Event Websites</li>
              <li>Virtual Event Hub Sites</li>
              <li>CMS Websites</li>
            </ul>
          </Column>
        </Article>
      </Section>
      <Section>
        <h2>Case Studies</h2>
        <p>
          Please find some of our recently produced events in our case study links below. All our
          projects are customised to the specific requirements of our clients and we will be
          delighted to share our experiences of best practice for your business event upon request.
        </p>
        <StaticCaseStudies caseStudies={caseStudies} />
      </Section>
      <LikeWhatYouSee margin />
    </>
  );
};

export const Head = () => (
  <link rel="preload" as="video" type="video/mp4" href="/design-video.mp4" />
);

const Directions = styled.div`
  align-items: center;
  display: flex;
  grid-column: span 2;
  justify-content: space-between;
  width: 100%;
`;

const BackButton = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.125rem;
  transition: 150ms ease-in-out;
  &:hover {
    background-color: #000;
  }
  &:not(:hover) {
    background-color: var(--ax-gold);
  }
  &:active {
    background-color: #c1beac;
  }
`;

const NextButton = styled(BackButton)`
  svg {
    transform: rotate(180deg);
  }
`;

const StyledPrevArrow = styled(PrevArrow)`
  width: 1.688rem;
`;

const StyledNextArrow = styled(PrevArrow)`
  width: 1.688rem;
`;

const StyledLink = styled(AnchorLink)`
  display: block;
  grid-column: 1;
  margin-top: -0.75em;
  margin-bottom: 1rem;
  @media (min-width: 48em) {
    grid-column: 1/3;
  }
`;

const ArticleTitle = styled.h3`
  grid-column: span 1;
  font-size: 1.5rem;
  font-weight: 800;
  text-align: left;
  @media (min-width: 48em) {
    grid-column: span 2;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-column: span 2;
  h3 {
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 2rem;
  }
  ul {
    list-style: none;
    li {
      font-size: 1rem;
      line-height: 2em;
      list-style-type: none;
      text-decoration: underline;
      -webkit-text-decoration-color: var(--ax-gold); /* Safari */
      text-decoration-color: var(--ax-gold);
      width: auto;
    }
  }
  p {
    line-height: 1.3em;
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
    strong {
      padding-left: 2rem;
      position: relative;
      display: inline-block;
      font-size: 0.75rem;
      line-height: 1.5em;
      &:before {
        content: '★';
        font-size: 1.25rem;
        color: var(--ax-gold);
        position: absolute;
        left: 0;
      }
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 300px;
  }
  @media (min-width: 48rem) {
    ul {
      li {
        font-size: 1.5rem;
      }
    }
  }
  @media (min-width: 62.5rem) {
    grid-column: span 1;
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
`;

const Article = styled.article`
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1.5rem;
  grid-column-gap: 3rem;
  margin: 2rem auto;
  margin-bottom: 0;
  width: 100%;
  @media (min-width: 62.5rem) {
    grid-row-gap: 2.5rem;
    grid-gap: 2.5rem;
    grid-template-columns: 1fr 1fr;
  }
`;

const Section = styled.section`
  width: 100%;
  height: auto;
  max-width: 108.75rem;
  padding: 2rem 0;
  margin: 0 auto;
  position: relative;
  > div {
    grid-column: 2/3;
  }
  > h2 {
    color: var(--ax-gold);
    font-size: 1.75rem;
    grid-column: 2/3;
    margin-bottom: 3rem;
    text-align: center;
  }
  > p {
    font-size: 1.25rem;
    line-height: 1.3em;
    text-align: center;
    grid-column: 2/3;
    margin-bottom: 3rem;
  }

  @media (min-width: 48rem) {
    padding: 3rem;
    > h2 {
      font-size: 2rem;
      text-transform: uppercase;
    }
    > p {
      margin-bottom: 5rem;
    }
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 1rem;
  svg {
    height: 1.5rem;
    align-self: flex-start;
    margin-bottom: 2rem;
  }
  @media (min-width: 48em) {
    svg {
      align-self: flex-start;
    }
  }
  @media (min-width: 67.5em) {
    svg {
      height: 2.5rem;
    }
  }
`;

const Video = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: relative;
  video {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    z-index: 1;
  }
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 0;
  }
`;

const HeroContent = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    grid-column: span 2;
  }
  h2 {
    font-size: 1.25rem;
    line-height: 1.3em;
    margin-bottom: 1em;
    font-weight: 300;
    grid-column: span 2;
  }
  @media screen and (min-width: 48rem) {
    h1 {
      font-size: 2.75rem;
      grid-column: span 1;
    }
    h2 {
      font-size: 2rem;
      grid-column: span 1;
    }
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    max-width: 1180px;
    margin: 3rem auto 3rem;
    align-items: start;
  }
  @media screen and (min-width: 67.5rem) {
    h1 {
      font-size: 4rem;
    }
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }
  @media screen and (min-width: 82.5rem) {
    h1 {
      font-size: 5rem;
    }
  }
`;

export default StudioPage;
